<template>
  <!--<ion-page @touchstart="checkLongPress(sound)" @touchend="stopAudio(sound)">-->
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">
        <ion-toolbar>
          <ion-buttons slot="start">
            <!--<ion-button slot="icon-only" @click="goBackToToolListPage()"><ion-icon :icon="arrowBack"></ion-icon></ion-button>-->
            <ion-button slot="icon-only" @click="checkExit"><ion-icon :icon="arrowBack"></ion-icon></ion-button>
          </ion-buttons>
          <ion-title v-show="backBtnClickCount == 0">{{ t('dogToy') }}</ion-title>
          <ion-title v-show="backBtnClickCount > 0">
            按多 {{ numOfBackBtnClickNeeded-backBtnClickCount }} 次離開
          </ion-title>
        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content @touchstart="checkLongPress(sound)" @touchend="stopAudio(sound)">
      <ion-grid fixed>
        <button :class="{ 'active-button': sound.isPlaying }">PUSH</button>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref } from 'vue';

// icons
import { arrowBack } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid,
        IonButtons, IonButton, IonIcon,
        onIonViewDidEnter, onIonViewWillLeave } from '@ionic/vue';

// composables
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

// plugins
import { StatusBar, } from "@capacitor/status-bar";
import { Howl, Howler } from 'howler';
import { Flashlight } from '@awesome-cordova-plugins/flashlight';
import { Vibration } from '@awesome-cordova-plugins/vibration';
import { Brightness } from '@ionic-native/brightness';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen';
import { Insomnia } from '@awesome-cordova-plugins/insomnia';

export default {
  name: 'ToyPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid,
                IonButtons, IonButton, IonIcon, },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { sleep, } = utils();
    
    let pressTimer;
    let isLooping = false;
    let vibrationInteval;

    const MAX_BRIGHTNESS_VAL = 0.8;
    const numOfBackBtnClickNeeded = 5;
    const backBtnClickCount = ref(0);
    let resetBackBtnCountTimeout = null;

    const sound = ref(JSON.parse(history.state.sound)); // may be from liked items page

    /**
     * Sound effects (e.g. beep)
     */
    const playAudio = async (sound, loop = false) => {
      sound.isPlaying = true;
      console.log(sound.url);
      const howlSound = new Howl({
        src: sound.url,
        sprite: {
            track01: sound.oneOffTrack || [10, 100],
            track02: sound.loopTrack || [40, 80, true],
        }
      });
      if (!loop) {
        //Brightness.setBrightness(MAX_BRIGHTNESS_VAL);
        Vibration.vibrate(100);
        howlSound.play('track01');
        setTimeout(() => {
          if (!isLooping) {
            sound.isPlaying = false;
            //Brightness.setBrightness(0);
          }
        }, 200);
      } else {
        //Brightness.setBrightness(MAX_BRIGHTNESS_VAL);
        isLooping = true;
        howlSound.play('track02');
        Vibration.vibrate(1000);
        vibrationInteval = setInterval(() => {
          Vibration.vibrate(1000);
        }, 300);
      }
    }
    const checkLongPress = (sound) => {
      playAudio(sound);
      if (pressTimer == null) {
        pressTimer = setTimeout(() => {
          playAudio(sound, true);
        }, 200)
      }
    }
    const stopAudio = (sound) => {
      if (vibrationInteval) {
        clearInterval(vibrationInteval);
        Vibration.vibrate(0);
      }
      if (pressTimer) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
      if (isLooping) {
        setTimeout(() => {
          Howler.stop();
          isLooping = false;
        }, 10);
      }
      if (sound) {
        sound.isPlaying = false;
      }
      //Brightness.setBrightness(0);
    }

    /**
     * Exit the page (long press the back button)
     */
    const goBackToToolListPage = () => {
      router.replace('/tools');
    }
    const checkExit = () => {
      //Brightness.setBrightness(0.8);
      clearTimeout(resetBackBtnCountTimeout);

      if (++(backBtnClickCount.value) >= numOfBackBtnClickNeeded) {
        goBackToToolListPage();
      }

      resetBackBtnCountTimeout = setTimeout(() => {
        backBtnClickCount.value = 0; 
      }, 1500);
    }


    onIonViewDidEnter(() => {
      //Brightness.setBrightness(0.5);
      Insomnia.keepAwake();
      StatusBar.hide();
      AndroidFullScreen.immersiveMode();
    });

    onIonViewWillLeave(() => {
      //Brightness.setBrightness(MAX_BRIGHTNESS_VAL);
      Insomnia.allowSleepAgain();
      StatusBar.show();
      AndroidFullScreen.showSystemUI();
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack,

      // variables
      sound, backBtnClickCount, numOfBackBtnClickNeeded,

      // methods
      t,
      playAudio, checkLongPress, stopAudio,
      checkExit, goBackToToolListPage,
    }
  }
}
</script>

<style scoped>
  button {
    outline: none;
    font-family:'helvetica neue' sans-serif; font-size:2em;
    color: #ffffff;
    text-shadow:-1px -1px 1px hsla(350,0%,0%,0.7), 1px 1px 1px hsla(350,0%,100%,0.3);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    padding: 47px 64px 50px 60px;
    cursor: pointer;
    background-color: hsla(350,80%,10%,1);
    background-image: linear-gradient(273deg,hsla(350,80%,60%,1) 30%, hsla(350,80%,50%,1) 40%);
    border: none;
    border-radius: 32px;
    box-shadow:
      inset 0px 0px 1px 1px hsla(350,80%,30%,0.9),
      inset 0px 0px 2px 3px hsla(350,80%,50%,0.9),
      inset 1px 1px 1px 4px hsla(350,80%,100%,0.8),
      inset 0px 0px 2px 7px hsla(350,80%,60%,0.8),
      inset 0px 0px 4px 10px hsla(350,80%,50%,0.9),
      8px 10px 2px 6px hsla(350,80%,20%,0.55),
      0px 0px 3px 2px hsla(350,80%,40%,0.9),
      0px 0px 2px 6px hsla(350,80%,50%,0.9),
      /*0px 0px 2px 3px hsla(350,80%,60%,0.9),*/
      -1px -1px 1px 6px hsla(350,80%,100%,0.9),
      /*0px 0px 2px 8px hsla(350,80%,60%,0.9),*/
      0px 0px 2px 11px hsla(350,80%,50%,0.9),
      0px 0px 1px 12px hsla(350,80%,40%,0.9),
      1px 3px 14px 14px hsla(350,80%,0%,0.4)
    ;
  }
  .active-button {
    color:hsla(350,0%,85%,1);
    padding: 52px 58px 44px 66px;
    background-image:
      linear-gradient(273deg,hsla(350,80%,50%,1) 50%, hsla(350,80%,55%,1) 60%)
    ;
    box-shadow:
      inset 3px 4px 3px 2px hsla(350,80%,20%,0.55),
      inset 0px 0px 1px 1px hsla(350,80%,30%,0.9),
      inset -1px -1px 2px 3px hsla(350,80%,50%,0.9),
      inset -2px -2px 1px 3px hsla(350,80%,100%,0.8),
      inset 0px 0px 2px 7px hsla(350,80%,60%,0.8),
      inset 0px 0px 3px 10px hsla(350,80%,50%,0.9),
      
      0px 0px 3px 2px hsla(350,80%,40%,0.9),
      0px 0px 2px 6px hsla(350,80%,50%,0.9),
      /*0px 0px 2px 3px hsla(350,80%,60%,0.9),*/
      -1px -1px 1px 6px hsla(350,80%,100%,0.9),
      /*0px 0px 2px 8px hsla(350,80%,60%,0.9),*/
      0px 0px 2px 11px hsla(350,80%,50%,0.9),
      0px 0px 1px 12px hsla(350,80%,40%,0.9),
      1px 3px 14px 14px hsla(350,80%,0%,0.4)
    ;
  }
</style>